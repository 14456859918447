<template>
    <router-view></router-view>
</template>

<script>
import {useRoute} from "vue-router"
export default {
  name: 'App',
  components: {
  },
  setup() {
      // 微信sdk注册
    const route=useRoute()
    if(route.query.customer_id){
      sessionStorage.setItem('customer_id',route.query.customer_id)
    }
  }
}
</script>

<style>
*{padding: 0;margin: 0;box-sizing: border-box;}
</style>
