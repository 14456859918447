import { createApp } from 'vue'
import App from './App.vue'
import vant from 'vant'
import 'vant/lib/index.css';
// 字体
import './assets/css/index.css';
import router from './routers'
import VueWechatTitle from 'vue-wechat-title';
router.beforeEach(async(to,form,next) => {
  if(to.query.customer_id){
    sessionStorage.setItem('customer_id',to.query.customer_id)
  }
  if(to.query.dealer_id){
    sessionStorage.setItem('dealer_id',to.query.dealer_id)
  }
  next()
})
createApp(App).use(router).use(VueWechatTitle).use(vant).mount('#app')
